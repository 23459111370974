import React from 'react'
import seoAnalysis from '../../assets/images/seo-analysis.png'
import {Link} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";

const D = () => {
    const {t} = useTranslation();

    return (
        <div className="seo-analysis-section ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="analysis-img">
                            <img src={seoAnalysis} alt="analysis" />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="analysis-form">
                            <span className="sub-title">{t('home.Di01')}</span>
                            <h3>{t('home.Dh3')}</h3>
                            <p>{t('home.Di02')}</p>
                            
                            <form>
                                {/*
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="your_name" placeholder="Name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="email" className="form-control" id="your_email" placeholder="Email" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="your_number" placeholder="Phone Number" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="website" placeholder="Website URL" />
                                        </div>
                                    </div>
                                </div>
                                */}

                                <div className="btn-box">
                                    <Link
                                        to="https://admin.crowlr.com/register"
                                        className="default-btn"
                                    >
                                        <i className="flaticon-right"></i>
                                        {t("Créeruncomptegratuit")}
                                        <span></span>
                                    </Link>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default D;